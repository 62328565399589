import { __decorate } from "tslib";
// Watch
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { userStore, atividadeStore, livroStore, mainStore, reposicaoStore, salaStore, turmaStore, } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import AtualizarLinkAulaComponent from '@/components/AtualizarLinkAulaComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newReposicao } from '@/interfaces/reposicao';
import _ from 'lodash';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => {
        if (typeof value === 'number' && value >= 0) {
            return true;
        }
        return !!value;
    },
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = {};
        this.search = '';
        this.selectedItem = {};
        this.selectedLivro = null;
        this.selectedProfessorDaTurma = null;
        this.selectedProfessorDaAula = null;
        this.atividades = [];
        this.turmasAlunos = [];
        this.selectedTurmaAluno = null;
        this.alunosProfessor = [];
        this.alunosReposicao = [];
        this.aulaDate = null;
        this.aulaTime = null;
        this.loading = false;
        this.professores = [];
    }
    get statusOptions() {
        return [
            { id: 1, descricao: 'Aguardando' },
            { id: 2, descricao: 'Aprovado' },
            { id: 3, descricao: 'Reprovado' },
            { id: 4, descricao: 'Agendado' },
            { id: 5, descricao: 'Desmarcado pelo aluno' },
            { id: 6, descricao: 'Falta' },
            { id: 7, descricao: 'Presença' },
        ];
    }
    get existAlunoCobrarReposicao() {
        for (const item of this.alunosReposicao) {
            if (item.cobrar_reposicao) {
                return true;
            }
        }
    }
    get salas() {
        return salaStore.salas;
    }
    get livros() {
        return livroStore.livros;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async onChangeSelectedLivro(val, oldVal) {
        if (val) {
            this.atividades = await atividadeStore.getAtividades(val.id);
        }
        else {
            this.atividades = [];
        }
    }
    async onChangeSelectedProfessor(val, oldVal) {
        if (val) {
            this.turmasAlunos = await turmaStore.getTurmasByProfessorId({
                professorId: val.id,
                livro: this.selectedLivro,
            });
            if (!this.selectedTurmaAluno && this.alunosReposicao.length > 0) {
                this.selectedTurmaAluno = _.find(this.turmasAlunos, (item) => {
                    return item.turma_id === this.alunosReposicao[0].turma_id;
                });
                if (!this.selectedTurmaAluno) {
                    const turmaId = this.alunosReposicao[0].turma_id;
                    const turma = await turmaStore.getTurma(turmaId);
                    const alunos = await turmaStore.getTurmaAlunosFicha(turmaId);
                    const options = [{ turma, alunos }];
                    this.turmasAlunos.push(options[0]);
                    this.selectedTurmaAluno = options[0];
                }
            }
        }
        else {
            this.turmasAlunos = [];
        }
    }
    async onChangeSelectedTurmaAlunos(val, oldVal) {
        if (val) {
            const alunos = await turmaStore.getTurmasAlunosByTurma({
                turma_id: val.turma_id,
            });
            if (this.id) {
                this.alunosProfessor = [];
                this.alunosReposicao = [...alunos];
                return;
            }
            this.alunosProfessor = [...alunos];
            this.alunosReposicao = [];
        }
        else {
            this.alunosProfessor = [];
            this.alunosReposicao = [];
        }
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            await this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.id) {
            const result = await reposicaoStore.updateReposicao(this.entityObject);
        }
        else {
            const result = await reposicaoStore.createReposicao(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
            const reposicoes_alunos = _.map(this.alunosReposicao, (item) => {
                const turma_aluno_atividade = _.find(item.turma_aluno_atividades, (atv) => {
                    return atv.atividade_id === this.entityObject.atividade_id;
                });
                return {
                    aluno_id: item.aluno_id,
                    reposicao_id: this.id,
                    turma_id: item.turma_id,
                    status: this.entityObject.status,
                    turma_aluno_id: item.turma_aluno_id ? item.turma_aluno_id : null,
                    turma_aluno_atividade_id: turma_aluno_atividade
                        ? turma_aluno_atividade.id
                        : null,
                    valor: 0
                };
            });
            await reposicaoStore.createReposicoesAlunos(reposicoes_alunos);
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
    }
    async mounted() {
        this.loading = true;
        await salaStore.getSalas();
        await livroStore.getLivros();
        this.professores = await userStore.getProfessores();
        const routerParamId = this.$router.currentRoute.params.id;
        if (routerParamId || this.propReforcoId) {
            this.id = this.propReforcoId || parseInt(routerParamId, 0);
            this.entityObject = await reposicaoStore.getReposicao(this.id);
            this.selectedLivro = _.find(this.livros, (livro) => livro.id === this.entityObject.atividade.livro_id);
            // @ts-ignore
            const data = new Date(this.entityObject.data_hora);
            if (this.entityObject.data_hora) {
                this.aulaDate = this.entityObject.data_hora.split('T')[0];
                // @ts-ignore
                this.aulaTime = this.formatTime(data);
            }
            this.selectedProfessorDaAula = this.entityObject.professor;
            this.selectedProfessorDaTurma = this.entityObject.professor_turma;
            if (this.selectedProfessorDaTurma) {
                this.professores.push(this.selectedProfessorDaTurma);
            }
            if (this.selectedProfessorDaAula) {
                this.professores.push(this.selectedProfessorDaAula);
            }
            this.alunosReposicao = await reposicaoStore.getReposicaoAlunos(this.entityObject.id);
        }
        else {
            this.entityObject = newReposicao();
        }
        this.loading = false;
    }
    changeItemFromTable(table, item) {
        let index = -1;
        let obj = {};
        switch (table) {
            case 0:
                index = _.findIndex(this.alunosReposicao, (aluno) => item.aluno_id === aluno.aluno_id);
                obj = this.alunosReposicao[index];
                this.alunosReposicao.splice(index, 1);
                this.alunosProfessor.push(obj);
                break;
            case 1:
                index = _.findIndex(this.alunosProfessor, (aluno) => item.aluno_id === aluno.aluno_id);
                obj = this.alunosProfessor[index];
                this.alunosProfessor.splice(index, 1);
                this.alunosReposicao.push(obj);
                break;
        }
    }
    async changeAprovacaoReposicao(value) {
        await reposicaoStore.changeAprovacaoReposicao({ value, id: this.id });
        this.entityObject = await reposicaoStore.getReposicao(this.id);
    }
};
__decorate([
    Prop({ type: Number, required: false })
], Create.prototype, "propReforcoId", void 0);
__decorate([
    Watch('selectedLivro')
], Create.prototype, "onChangeSelectedLivro", null);
__decorate([
    Watch('selectedProfessorDaTurma')
], Create.prototype, "onChangeSelectedProfessor", null);
__decorate([
    Watch('selectedTurmaAluno')
], Create.prototype, "onChangeSelectedTurmaAlunos", null);
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
            DateFieldComponent,
            AtualizarLinkAulaComponent
        },
    })
], Create);
export default Create;
