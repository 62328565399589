import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, reposicaoStore, analyticsStore, } from '@/store';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
import Create from './Create.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import _ from 'lodash';
let ReposicaoList = class ReposicaoList extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.tab = 0;
        this.selectPeriodDate = null;
        this.reforcoId = null;
        this.headers = [
            {
                text: 'Horário',
                table: 'reposicao',
                value: 'data_hora',
                show: true,
            },
            {
                text: 'Sala',
                value: 'sala_nome',
                table: 'sala',
                field: 'nome',
                type: 'str',
                show: true,
            },
            {
                text: 'Alunos',
                value: 'alunos',
                table: 'aluno',
                type: 'str',
                show: true,
            },
            {
                text: 'Professor',
                value: 'professor_full_name',
                table: 'professor',
                field: 'full_name',
                type: 'str',
                show: true,
            },
            {
                text: 'Professor da turma',
                value: 'professor_turma_full_name',
                table: 'professor_turma',
                field: 'full_name',
                type: 'str',
                show: true,
            },
            {
                text: 'Atividade',
                value: 'atividade_descricao',
                table: 'atividade',
                field: 'descricao',
                type: 'str',
                show: true,
            },
            {
                text: 'Motivação',
                value: 'motivo_id',
                table: 'reposicao',
                show: true,
            },
            {
                text: 'Status',
                value: 'status',
                table: 'reposicao',
                show: true,
            },
            {
                text: 'Ações',
                value: 'actions',
                show: true,
            },
        ];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Reforços`]) {
            return userToolStore.userToolsIndexed[`Reforços`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Reforços`]) {
            return userToolStore.userToolsIndexed[`Reforços`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Reforços`]) {
            return userToolStore.userToolsIndexed[`Reforços`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Reforços`]) {
            return userToolStore.userToolsIndexed[`Reforços`][0].allow_delete;
        }
    }
    get totals() {
        return analyticsStore.totals;
    }
    get reposicoes() {
        return reposicaoStore.reposicoes;
    }
    aulaExtraStatusOptions(id) {
        switch (id) {
            case 1:
                return 'Aguardando';
            case 2:
                return 'Aprovado';
            case 3:
                return 'Reprovado';
            case 4:
                return 'Agendado';
            case 5:
                return 'Desmarcado pelo aluno';
            case 6:
                return 'Falta';
            case 7:
                return 'Presença';
        }
    }
    tipoOptions(id) {
        switch (id) {
            case 0:
                return 'Reposição';
            case 1:
                // @ts-ignore
                return this.getNomeAulaExtraSingular();
            case 2:
                return 'Reposição gratuita';
        }
    }
    reposicaoStatusOptions(id) {
        switch (id) {
            case 0:
                return 'Aula não lecionada';
            case 1:
                return 'Aula lecionado';
        }
    }
    filter(value, search, item) {
        const inDescription = RegExp(search, 'i').test(value);
        // --------------------------------------------------
        const aluno_nomes = _.map(item.alunos, 'aluno_nome');
        const r = aluno_nomes.some((i) => {
            return RegExp(search, 'i').test(i);
        });
        // --------------------------------------------------
        return inDescription || r;
    }
    async deleteItem(id) {
        // @ts-ignore
        this.$swal({
            // @ts-ignore
            title: `Confirma a exclusão da ${this.getNomeAulaExtraSingular()}?`,
            // @ts-ignore
            text: `Após exclusão a ${this.getNomeAulaExtraSingular()} não pode ser recuperada!`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await reposicaoStore.deleteReposicao(id);
                this.doSearchTotals();
                this.buscar();
                this.loading = false;
            }
        });
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'reposicao.data_hora': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        this.doSearchTotals();
        analyticsStore.executeSearchItems();
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsAulaExtra({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(reposicaoStore.getReposicoesAulaExtraQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que nao tem defaultFilter precisa ter o buscar() aqui
        // this.buscar();
    }
    routeEdit(id) {
        this.reforcoId = id;
        const form = this.$refs.showReforcos;
        form.show();
    }
};
ReposicaoList = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            ShowPopup,
            Create,
            OpenNewTabComponent,
        },
    })
], ReposicaoList);
export default ReposicaoList;
